import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";

const useStyles = makeStyles((theme) => ({
  header: {
    paddingTop: 0,
  },
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

function TestimonialsSection(props) {
  const classes = useStyles();

  const items = [
    {
      avatar: "https://imagizer.imageshack.com/img924/5647/G7VF21.jpg",
      name: "Jeff White and Family",
      testimonial:
        "Mrs. Pacheco has been our faithful notary since she started. We appreciate her commitment to checking all of the details while being pleasant and professional. We highly recommend her services for whatever notary needs you may have!!",
      company: "",
    },
    {
      avatar: "https://imagizer.imageshack.com/img922/8471/l6OBLS.jpg",
      name: "Gabriella Anastasakis",
      testimonial:
        "I called Lissett on a Saturday evening and used her service on a Sunday morning at 8 am. I couldn't be more happier. Professional, friendly and always available. I highly recommend to others.",
      company: "",
    },
    {
      avatar: "https://imagizer.imageshack.com/img924/218/Z0Skvf.jpg",
      name: "Joseph Campitiello",
      testimonial:
        "Would definitely recommend, I needed a trust notarized and my call was answered promptly, we were able to schedule to meet very easily and conveniently.",
      company: "",
    },
  ];

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />
        <Grid container={true} justifyContent="center" spacing={4}>
          {items.map((item, index) => (
            <Grid item={true} xs={12} sm={4} key={index}>
              <Card>
                <CardContent>
                  <Typography variant="body1" component="p">
                    "{item.testimonial}"
                  </Typography>
                </CardContent>
                <CardHeader
                  className={classes.header}
                  avatar={
                    <Avatar
                      src={item.avatar}
                      alt={item.name}
                      className={classes.avatar}
                    />
                  }
                  title={item.name}
                  subheader={item.company}
                />
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Section>
  );
}

export default TestimonialsSection;
