import React from "react";
import Meta from "./../components/Meta";
import HeroSection2 from "./../components/HeroSection2";
import TeamBiosSection from "./../components/TeamBiosSection";
const fl1 =require('../assets/img/fl1.jpeg');
const cb =require('../assets/img/cb.jpeg');

function AboutPage(props) {
  return (
    <>
      <Meta title="About" description="Learn about Lissett" />
      <HeroSection2
        bgColor="default"
        size="large"
        bgImage={fl1}
        bgImageOpacity='40%'
        title="Here for your convenience"
        subtitle="In the Tampa Bay area for over 20 years. Bilingual in English/Spanish."
      />
      {/* <StatsSection
        bgColor="light"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
      /> */}
      <img style={{width:'100%'}} src={cb}/>
      <br/>
      <br/>
      <a href="https://www.lissettpacheco.com" target="_blank" style={{textAlign:'center', color:'teal', fontSize:'2rem', fontFamily:'verdana'}}>www.lissettpacheco.com</a>
      <TeamBiosSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Nice to meet you!"
        subtitle="Let me be your local guide."
      />
      {/* <CtaSection
        bgColor="primary"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Ready to get started?"
        subtitle=""
        buttonText="Get Started"
        buttonColor="default"
        buttonPath="/pricing"
      /> */}
    </>
  );
}

export default AboutPage;
