import React from "react";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { emphasize } from "@material-ui/core/styles/colorManipulator";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import AspectRatio from "./AspectRatio";
const l0 = require('../assets/img/l0.jpeg');
const l1 =require('../assets/img/l3.png');
const l2 =require('../assets/img/l2.jpeg');
const l3 =require('../assets/img/l4.jpeg');

const useStyles = makeStyles((theme) => ({
  gridItem: {
    // Add border that contrasts lightly with background color.
    // We use boxShadow so that it's hidden around outer edge
    // due to container <Card> having overflow: hidden
    boxShadow: `1px 1px 0 0 ${emphasize(theme.palette.background.paper, 0.08)}`,
    textAlign: "center",
  },
  imageContainer: {
    margin: "0 auto",
    maxWidth: "200px",
    marginBottom: "30px",
    "& img": {
      width: "100%",
    },
  },
}));

function FeaturesSection(props) {
  const classes = useStyles();

  const items = [
    {
      title: "Mobile notary services",
      body: "Notarizations at Hospitals and Nursing Homes;  Mobile and Remote Online Notarizations (Internet session);  Loan Signing Package: Deed, Trust, Will, Power of Attorney;  Wedding Officiant",
      image: 'https://imagizer.imageshack.com/img923/905/V0z1Ar.jpg',
    },
    {
      title: "Immigration document services",
      body: "New Passport Application and renewals;  Consent for minor travel - Children Consent;  General Documents and Forms -- Forms I-130, I-485, N-400, Employment Authorization;  Print and Scan service;  Passport copy certificate",
      image: 'https://www.onawa.lib.ia.us/application/files/6516/1377/1854/notary_services.jpg',
    },
    {
      title: "Notary signing services",
      body: "Apostille Services;  Affidavit;  Translations English into Spanish or Spanish into English;  Proof of life. Prueba de vida.",
      image: 'https://rainbownotaryandnuptials.com/wp-content/uploads/2021/12/Rainbow-_-Remote-Online-Notary-1.png',
    },
     {
      title: "Types of Documents",
      body: "Estate planning documents-- Deed, Trust, Will, Power of Attorney;  Business document notarization-- Copy Certificate: Florida drivers License, Acknowledgment, Jurat, Verbal Oath;  Vehicle-related documents-- Vehicle title transfer, Puerto Rico-Car title translation and certification, VIN certifications",
      image: 'https://images.pexels.com/photos/7821688/pexels-photo-7821688.jpeg',
    },
  ];

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />
        <Card raised={false}>
          <Grid container={true}>
            {items.map((item, index) => (
              <Grid
                item={true}
                xs={12}
                md={6}
                className={classes.gridItem}
                key={index}
              >
                <Box p={6}>
                  <div className={classes.imageContainer}>
                    <AspectRatio ratio={4 / 3}>
                      <img src={item.image} alt={item.title} />
                    </AspectRatio>
                  </div>
                  <Typography variant="h5" gutterBottom={true}>
                    {item.title}
                  </Typography>
                  <Typography variant="subtitle1">{item.body}</Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Card>
      </Container>
    </Section>
  );
}

export default FeaturesSection;
